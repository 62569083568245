@use '@angular/material' as mat;
@import '@angular/material/theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$primary: (
  50: #e4e8eb,
  100: #bac5ce,
  200: #8d9fad,
  300: #5f798c,
  400: #3c5c74,
  500: #1a3f5b,
  600: #173953,
  700: #133149,
  800: #0f2940,
  900: #081b2f,
  A100: #69aaff,
  A200: #368dff,
  A400: #0370ff,
  A700: #0065e9,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: white,
    A400: white,
    A700: white
  )
);

$app-primary: mat.define-palette($primary, 600);
$app-accent: mat.define-palette($primary, 600);

$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
  ),
  density: 0
));

@include mat.all-legacy-component-themes($app-theme);

$app-typography: mat.define-legacy-typography-config(
  $font-family: '"ZonaPro", "Helvetica Neue", sans-serif'
);

@include mat.all-legacy-component-typographies($app-typography);

$background: map-get($app-theme, background);

.mat-body, .mat-body-1, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {
  font: 400 14px "ZonaPro", "Helvetica Neue", sans-serif;
}

.mat-body p, .mat-body-1 p, .mat-typography .mat-body p, .mat-typography .mat-body-1 p, .mat-typography p {
  margin: 0 0 20px;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-dialog-container {
  background: var(--swatch1);
  border-radius: 0 !important;
  color: var(--swatch3);
  opacity: 0.95;
}

.mat-dialog-title {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  place-content: center space-between;
}
