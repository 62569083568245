@import 'node_modules/ngx-sharebuttons/themes/circles';

@font-face {
  font-family: 'ZonaPro';
  src: url('assets/fonts/ZonaPro.otf') format('opentype');
}

@font-face {
  font-family: 'ZonaPro-Bold';
  src: url('assets/fonts/ZonaPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ZonaPro-ExtraBoldItalic';
  src: url('assets/fonts/ZonaPro-ExtraBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'ZonaPro-ThinItalic';
  src: url('assets/fonts/ZonaPro-ThinItalic.otf') format('opentype');
}

:root {
  --swatch1: #17479d;
  --swatch2: #f16522;
  --swatch3: #ffffff;
  --swatch4: #3471df;
  --swatch5: #c90c26;
  --swatch6: #169f4d;
  --swatch7: #ff7e40;
  --swatch8: #4e8dff;
  --swatch9: #db2c44;
  --swatch10: #2bb964;
  --swatch11: #363636 0;
  --swatch12: rgba(0, 0, 0, 0);
}

b {
  font-family: 'ZonaPro-Bold', sans-serif;
}

body {
  font-family: 'ZonaPro', sans-serif;
  height: 100vh;
  margin: 0;
}

.button {
  border-radius: 4px;
  color: var(--swatch3) !important;
  font-family: 'ZonaPro-Bold', sans-serif !important;
  line-height: inherit !important;
  text-align: center;
  transition: 0.2s all ease-in;
}

.button[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.button:hover {
  cursor: pointer;
}

.button1 {
  @extend .button;
  background: var(--swatch2);
}

.button1:hover {
  background: var(--swatch7);
}

.button2 {
  @extend .button;
  background: var(--swatch1) !important;
}

.button2:hover {
  background: var(--swatch4) !important;
}

.button3 {
  @extend .button;
  background: var(--swatch4);
}

.button3:hover {
  background: var(--swatch8);
}

.button4 {
  @extend .button;
  background: var(--swatch5);
}

.button4:hover {
  background: var(--swatch9);
}

.button5 {
  @extend .button;
  background: var(--swatch6);
}

.button5:hover {
  background: var(--swatch10);
}

.default-padding {
  padding: 0 40px;
}

html {
  height: 100vh;
}

form {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  place-content: space-between;

  .mat-form-field {
    margin-top: 5px;
    width: 48%;
  }

  textarea {
    height: 300px;
  }
}

.full-width-element {
  width: 100% !important;
}

.margin-bottom20 {
  margin-bottom: 20px !important;
}

.margin-left20 {
  margin-left: 20px !important;
}

.margin-right20 {
  margin-right: 20px !important;
}

.margin-top20 {
  margin-top: 20px !important;
}

p:last-child {
  margin: 0 !important;
}
